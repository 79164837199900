<template>
  <div class="relative">
    <div class="flex items-center justify-between bg-send-grey-nav p-2 shadow">
      <Logo />

      <div v-if="loggedIn" class="flex items-center gap-2">
        <FwbAvatar
          rounded
          status-position="bottom-right"
          status="online"
          class="rounded-full border border-black"
          :img="avatar ?? undefined"
          :initials="displayName?.substring(0, 1) ?? 'A'"
        />
        <LogoutButton />
      </div>
    </div>

    <div
      class="relative flex h-screen min-h-screen w-full flex-col overflow-y-auto bg-send-grey-bg"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import FwbAvatar from "~/components/Flowbite/FwbAvatar/FwbAvatar.vue";
import LogoutButton from "~/components/auth/LogoutButton.vue";
import Logo from "~/components/partials/logo.vue";

const userStore = useUserStore();
const authStore = useAuthStore();
const { loggedIn } = toRefs(authStore);
const { displayName, avatar } = toRefs(userStore);
</script>
